import { getDecodedLoggedInUser } from "../../utils";

const socketConnectionReducer = (
  state = {
    socketConnected: false,
    chatRoomToUserMapping: {},
    typingRoomToUserMapping: {},
  },
  action
) => {
  switch (action.type) {
    case "connected":
      return {
        ...state,
        socketConnected: true,
      };
    case "user-joined":
      let chatId = action.data.chat._id;
      let userName = action.data.user.name;
      let userId = action.data.user.id;
      let newChatRoomToUserMapping = state.chatRoomToUserMapping;
      // console.log("User joined room", userName, userId, chatId);
      if (userId !== getDecodedLoggedInUser().id) {
        if (chatId in state.chatRoomToUserMapping) {
          const isUserAlreadyJoined = newChatRoomToUserMapping[chatId].filter(
            (userarr) => userarr.userId === userId
          );
          if (isUserAlreadyJoined.length === 0) {
            newChatRoomToUserMapping[chatId].push({ userName, userId });
          }
        } else {
          newChatRoomToUserMapping[chatId] = [
            {
              userName,
              userId,
            },
          ];
        }
      }

      return {
        ...state,
        chatRoomToUserMapping: newChatRoomToUserMapping,
      };
    case "user-left":
      chatId = action.data.chat._id;
      userName = action.data.user.name;
      userId = action.data.user.id;
      newChatRoomToUserMapping = state.chatRoomToUserMapping;
      // console.log("User left room", userName, userId, chatId);
      if (chatId in state.chatRoomToUserMapping) {
        newChatRoomToUserMapping[chatId] = newChatRoomToUserMapping[
          chatId
        ].filter((userarr) => userarr.userId !== userId);
      }

      return {
        ...state,
        chatRoomToUserMapping: newChatRoomToUserMapping,
      };

    case "user-typing":
      chatId = action.data.chat._id;
      userName = action.data.user.name;
      userId = action.data.user.id;
      let typeRoom = state.typingRoomToUserMapping;
      // console.log("Typing is going in room", userName, userId, chatId);
      if (userId !== getDecodedLoggedInUser().id) {
        if (chatId in state.typingRoomToUserMapping) {
          const isUserAlreadyTyping = typeRoom[chatId].some(
            (userarr) => userarr.userId === userId
          );
          if (!isUserAlreadyTyping) {
            typeRoom[chatId].push({ userName, userId });
          }
        } else {
          typeRoom[chatId] = [{ userName, userId }];
        }
      }

      return {
        ...state,
        typingRoomToUserMapping: typeRoom,
      };

    case "user-stopped-typing":
      console.log("called non stop");
      chatId = action.data.chat._id;
      userName = action.data.user.name;
      userId = action.data.user.id;
      // console.log("Typing is stopped in room", userName, userId, chatId);
      let typeRoom1 = state.typingRoomToUserMapping;
      if (chatId in typeRoom1) {
        typeRoom1[chatId] = typeRoom1[chatId].filter(
          (userarr) => userarr.userId !== userId
        );
      }
      return {
        ...state,
        typingRoomToUserMapping: typeRoom1,
      };

    default:
      return state;
  }
};

export default socketConnectionReducer;
