export const COLLAPSE_MENU = "COLLAPSE_MENU";
export const COLLAPSE_TOGGLE = "COLLAPSE_TOGGLE";
export const FULL_SCREEN = "FULL_SCREEN";
export const FULL_SCREEN_EXIT = "FULL_SCREEN_EXIT";
export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const NAV_CONTENT_LEAVE = "NAV_CONTENT_LEAVE";
export const NAV_COLLAPSE_LEAVE = "NAV_COLLAPSE_LEAVE";
export const SET_NYC_TICKETS = "SET_NYC_TICKETS";
export const SET_NYC_TICKETS_LOADED = "SET_NYC_TICKETS_LOADED";
export const SET_TWILIO_DEVICE = "SET_TWILIO_DEVICE";
