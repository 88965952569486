import { getDecodedLoggedInUser } from "../../utils";

const selectedChatReducer = (state = {}, action) => {
  switch (action.type) {
    case "SELECTED_CHAT":
      // The payload in this action is the entire contact with all events (messages)
      return action.payload;
    case "SEND_MESSAGE":
      // The payload in this action is only the single new event (message), not the entire contact
      // The payload of the new event shouldn't be added for the sender user, 
      // only the other client users who should get updated about the message sent
      let user = getDecodedLoggedInUser();
      if (state._id === action.payload.customerId && user.id !== action.payload.userId) {
        const arr = state.events;
        arr.push(action.payload);
        return {
          ...state,
          events: arr,
        };
      } else {
        // adding refreshContactList to trigger a state change that will trigger contactlist refresh
        return state;
      }
    case "RECEIVE_MESSAGE":
      // The payload in this action is only the single new event (message),
      // not the entire contact
      if (state._id === action.payload.customerId) {
        const arr = state.events;
        arr.push(action.payload);
        return {
          ...state,
          unaddressed: true,
          events: arr,
        };
      } else {
        // adding refreshContactList to trigger a state change that will trigger contactlist refresh
        return state;
      }
    case "TWILIO_UPDATED_EVENT":
      // The payload in this action is only the single new event (message),
      // not the entire contact
      if (state._id === action.payload.customerId) {
        const arr = state.events;
        // arr.pop();
        arr[arr.length - 1] = action.payload;
        return {
          ...state,
          unaddressed: true,
          events: arr,
        };
      } else {
        // adding refreshContactList to trigger a state change that will trigger contactlist refresh
        return {
          ...state,
          refreshContactList: true,
        };
      }
    case "TWILIO_UPDATED_INCOMING_EVENT":
      // The payload in this action is only the single new event (message),
      // not the entire contact
      if (state._id === action.payload.customerId) {
        // const arr = state.events;
        // arr.pop();
        // arr[arr.length - 1] = action.payload;
        return {
          ...state,
          unaddressed: true,
          events: [...state.events, action.payload],
        };
      } else {
        // adding refreshContactList to trigger a state change that will trigger contactlist refresh
        return {
          ...state,
          refreshContactList: true,
        };
      }
    default:
      return state;
  }
};

export default selectedChatReducer;
