import { combineReducers, createStore, applyMiddleware } from "redux";
import userActionReducer from "./reducers/userActionReducer";
import reducer from "./reducer";
import sidebarReducer from "./reducers/sidebarReducer";
import socketConnectionReducer from "./reducers/socketConnectionReducer";
import mobileSidebarReducer from "./reducers/mobileSidebarReducer";
import profileSidebarReducer from "./reducers/profileSidebarReducer";
import mobileProfileSidebarReducer from "./reducers/mobileProfileSidebarReducer";
import pageTourReducer from "./reducers/pageTourReducer";
import selectedChatReducer from "./reducers/selectedChatReducer";
import contactListReducer from "./reducers/contactListReducer";
import unaddressedCountReducer from "./reducers/unaddressedCountReducer";
import createSocketIoMiddleware from "redux-socket.io";
import io from "socket.io-client";
import config from "../config";

let socket = io(config.prod, {
  transports: ["websocket"],
});

let socketIoMiddleware = createSocketIoMiddleware(socket, "server/");

const reducers = combineReducers({
  reducer,
  userDetails: userActionReducer,
  selectedSidebar: sidebarReducer,
  mobileSidebar: mobileSidebarReducer,
  profileSidebar: profileSidebarReducer,
  mobileProfileSidebar: mobileProfileSidebarReducer,
  socketConnection: socketConnectionReducer,
  pageTour: pageTourReducer,
  selectedChat: selectedChatReducer,
  refreshContactList: contactListReducer,
  unaddressedCount: unaddressedCountReducer,
});

const store = applyMiddleware(socketIoMiddleware)(createStore)(reducers);

export default store;
