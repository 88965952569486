const contactListReducer = (state = {}, action) => {
    switch (action.type) {
        case 'REFRESH_CONTACT_LIST':
            // console.log("State ClientId", state.clientId, "payload ClientId", action.payload.clientId)
            // if a refresh is requested, the client that received the message should be the only 
            // one we refresh the contact list for
            if (action.payload.refreshContactList) {
                if (state.clientId === action.payload.clientId) {
                    return action.payload;
                }
                else {
                    return state;
                }
            } else {
                return action.payload;
            }
        default:
            return state;
    }
};

export default contactListReducer;